import React from "react";
import { Card, CardHeader, CardBody, CardText } from "reactstrap";
import { PrivacyPolicyData } from "../common/data/privacy-policy";
import { Fragment } from "react";
import Container from "../common/components/UI/Container";
import Logo from "../common/components/UIElements/Logo";
import LogoImage from "../common/assets/image/logo/bitexbot-logo-text.png";
import Seo from "components/seo";

const PrivacyPolicy = () => {
  const lastUpdate = "September 02, 2021";
  return (
    <Fragment>
      <Seo
        title="Privacy Policy"
        description={`Bitexbot Privacy Policy last update on: ${lastUpdate}`}
      />
      <section className="w-100 text-center my-3">
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="Bitexbot"
          className="main-logo"
        />
      </section>
      <section>
        <Container className="px-5">
          <Card>
            <CardHeader>{`Last updated on: ${lastUpdate}`}</CardHeader>
            <CardBody>
              <CardText tag="h2" className="mb-2">
                Privacy Policy
              </CardText>
              <CardText>
                <strong>In brief</strong>: We are Bitexbot ("we" or "us"). We
                respect your privacy and work hard to protect the
                confidentiality of information that you give us. We will share
                your personal data with third parties only in the event that
                it’s needed for the provision of services and data you have
                requested (explained in detail in this privacy policy).
              </CardText>
              {PrivacyPolicyData.map((terms, index) => {
                return (
                  <Fragment>
                    <CardText tag="h4">{terms.title}</CardText>
                    <CardText
                      className="mb-2"
                      dangerouslySetInnerHTML={{ __html: terms.description }}
                    />
                  </Fragment>
                );
              })}
            </CardBody>
          </Card>
        </Container>
      </section>
    </Fragment>
  );
};
export default PrivacyPolicy;
