export const PrivacyPolicyData = [
  {
    title: 'Personal identification information',
    description: `
        We may collect personal identification information from our users (each, a "User")
        in a variety of ways, including, but not limited to, when Users visit our site, register on the siteplace 
        an orderfill out a formsubscribe to the newsletter and in connection with other 
        activities, services, features or resources we make available on our Site. 
        Users may be asked for, as appropriate, name, email address, mailing address, 
        phone number, Users may, however, visit our Site anonymously.
        <br /><br />
        We will collect personal identification information from Users only if they 
        voluntarily submit such information to us. Users can always refuse to supply personally 
        identification information, except that it may prevent them from engaging in certain 
        Site related activities.
      `,
  },
  {
    title: 'Non-personal identification information',
    description: `
        We may collect non-personal identification information about Users whenever they 
        interact with our Site. Non-personal identification information may include the 
        browser name, the type of computer and technical information about Users means of 
        connection to our Site, such as the operating system and the Internet service 
        providers utilized and other similar information.
      `,
  },
  {
    title: 'Web browser cookies',
    description: `
        Our Site may use "cookies" to enhance User experience. User's web browser places 
        cookies on their hard drive for record-keeping purposes and sometimes to track 
        information about them. User may choose to set their web browser to refuse cookies, 
        or to alert you when cookies are being sent. If they do so, note that some parts 
        of the Site may not function properly.
      `,
  },
  {
    title: 'How we use collected information',
    description: `
      Bitexbot collects and uses Users personal information for the following purposes:
      <br />
      <ul>
        <li>To improve customer service</li>
        <li>Your information helps us to more effectively respond to your customer service 
        requests and support needs.</li>
        <li>To personalize user experience</li>
        <li>We may use information in the aggregate to understand how our Users as a group 
        use the services and resources provided on our Site.</li>
        <li>To improve our Site</li>
        <li>We continually strive to improve our website offerings based on the information 
        and feedback we receive from you.</li>
        <li>To process transactions</li>
        <li>We may use the information Users provide about themselves when placing an order 
        only to provide service to that order. We do not share this information with outside 
        parties except to the extent necessary to provide the service.</li>
        <li>To send periodic emails</li>
      </ul>
      The email address Users provide for order processing, will only be used to send them 
      information and updates pertaining to their order. It may also be used to respond 
      to their inquiries, and/or other requests or questions. If User decides to opt-in to 
      our mailing list, they will receive emails that may include news, updates, related 
      product or service information, etc. If at any time the User would like to unsubscribe 
      from receiving future emails, we include detailed unsubscribe instructions at the bottom 
      of each email or User may contact us via our Site.
      `,
  },
  {
    title: 'How we protect your information',
    description: `
        We adopt appropriate data collection, storage and processing practices and security 
        measures to protect against unauthorized access, alteration, disclosure or destruction 
        of your personal information, username, password, transaction information and data 
        stored on our Site.
        <br /><br />
        Sensitive and private data exchange between the Site and its Users happens over a SSL 
        secured communication channel and is encrypted and protected with digital signatures.
      `,
  },
  {
    title: 'Third party websites',
    description: `
        Users may find advertising or other content on our Site that link to the sites and 
        services of our partners, suppliers, advertisers, sponsors, licensors and other 
        third parties. We do not control the content or links that appear on these sites 
        and are not responsible for the practices employed by websites linked to or from 
        our Site. In addition, these sites or services, including their content and links, 
        may be constantly changing. These sites and services may have their own privacy 
        policies and customer service policies. Browsing and interaction on any other website, 
        including websites which have a link to our Site, is subject to that website's own 
        terms and policies.    
      `,
  },
  {
    title: 'Advertising',
    description: `
        Ads appearing on our site may be delivered to Users by advertising partners, who may 
        set cookies. These cookies allow the ad server to recognize your computer each time 
        they send you an online advertisement to compile non personal identification information 
        about you or others who use your computer. This information allows ad networks to, 
        among other things, deliver targeted advertisements that they believe will be of most 
        interest to you. This privacy policy does not cover the use of cookies by any advertisers.
      `,
  },
  {
    title: 'Changes to this privacy policy',
    description: `
        Bitexbot has the discretion to update this privacy policy at any time. When we do, 
        revise the updated date at the bottom of this page,. We encourage Users to frequently 
        check this page for any changes to stay informed about how we are helping to protect 
        the personal information we collect. You acknowledge and agree that it is your 
        responsibility to review this privacy policy periodically and become aware of 
        modifications. 
      `,
  },
  {
    title: 'Your acceptance of these terms',
    description: `
        By using this Site, you signify your acceptance of this policy and terms of service. 
        If you do not agree to this policy, please do not use our Site. Your continued use of 
        the Site following the posting of changes to this policy will be deemed your acceptance 
        of those changes.  
      `,
  },
  {
    title: 'Contacting us',
    description: `
        If you have any questions about this Privacy Policy, the practices of this site, 
        or your dealings with this site, please contact us at <a href="mailto:support@bitexbot.org">support@bitexbot.org</a>.
      `,
  },
];
